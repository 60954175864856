import { cn } from "@peerigon/pupper/tailwind";
import { CmsImage } from "src/components/Image/CmsImage";
import { readCmsImageData } from "src/components/Image/utils";
import Link from "src/components/Link/Link";

import { ColorType, readAsColor } from "src/enums";
import { colors } from "src/styles/variables";
import type { HeaderFieldsFragment } from "src/__generated__/graphql";

type SubNavigationItemProps = {
	data: HeaderFieldsFragment["items"][0]["menuColumns"][0]["items"][0];
	pathname: string;
};

const SubNavigationItem: React.FC<SubNavigationItemProps> = ({
	data,
	pathname,
}) => {
	const { title, icon, color, description, page } = data;

	const colorsMap: Record<ColorType, { light: string; dark: string }> = {
		blue: {
			light: colors.moonRakerLight,
			dark: colors.moonRaker,
		},
		green: {
			light: colors.peppermintLight,
			dark: colors.peppermint,
		},
		yellow: {
			light: colors.yellowLight,
			dark: colors.yellow,
		},
	};

	const isActiveLink = page?.routeName
		? pathname.includes(page.routeName)
		: false;

	const selectedColor = readAsColor(color, "blue");

	return (
		<Link
			to={page?.routeName ?? ""}
			style={{
				"--subnav-item-color-light": colorsMap[selectedColor].light,
				"--subnav-item-color-dark": colorsMap[selectedColor].dark,
			}}
			className={cn(
				"group flex w-[400px] max-w-full cursor-pointer select-none flex-row items-center gap-5 rounded-lg px-5 py-6 disable-anchor-shadow-styles focus:outline-[3px] focus:outline-[var(--subnav-item-color-dark)] focus-visible:!outline-[3px] focus-visible:!outline-[var(--subnav-item-color-dark)]",
				isActiveLink
					? "bg-[var(--subnav-item-color-dark)]"
					: "hover:bg-[var(--subnav-item-color-light)] focus:bg-[var(--subnav-item-color-light)]",
			)}
		>
			<div
				className={cn(
					"flex-shrink-0 rounded-lg bg-[var(--subnav-item-color-light)] p-4 group-hover:bg-[var(--subnav-item-color-dark)] group-focus:bg-[var(--subnav-item-color-dark)]",
					isActiveLink && "bg-[var(--subnav-item-color-dark)]",
				)}
			>
				{icon ? (
					<CmsImage
						image={readCmsImageData(icon)}
						style={{
							width: "30px",
							height: "30px",
						}}
					/>
				) : null}
			</div>
			<div className="grid grid-cols-1 gap-3 text-almostBlack">
				<div className="text-px-20 font-medium leading-medium">
					{title}
				</div>
				{description ? (
					<div className="truncate text-px-16 font-light leading-regular-3">
						{description}
					</div>
				) : null}
			</div>
		</Link>
	);
};

export default SubNavigationItem;
