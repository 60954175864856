"use client";

import { cn } from "@peerigon/pupper/tailwind";
import * as ScrollAreaPrimitive from "@radix-ui/react-scroll-area";
import { useTranslations } from "next-intl";
import { getFragmentData } from "src/__generated__";
import NavLeftIcon from "src/assets/navLeft.svg";
import NavRightIcon from "src/assets/navRight.svg";
import Button from "src/components/ButtonNew/Button";
import Card from "src/components/Card/Card";
import { useTeaserCards } from "src/components/CaseStudyTeaser/CaseStudyTeaser.hooks";
import { readCmsImageData } from "src/components/Image/utils";
import IntroBlock from "src/components/IntroBlock/IntroBlock";
import BaseSection from "src/components/sections/BaseSection";
import { CaseStudyListElementFragment } from "src/components/sections/CaseStudyListSection/CaseStudyListSection";
import { ContentContainerTw } from "src/components/styled/ContentContainer";

import { Spacing } from "src/enums";
import { stringListToArray } from "src/hygraph-to-dato";
import type { GetCaseStudyTeaserQuery } from "src/__generated__/graphql";

const CaseStudyTeaser: React.FC<{
	caseStudyTeaserSlots: GetCaseStudyTeaserQuery["caseStudyTeaserSlots"];
}> = ({ caseStudyTeaserSlots }) => {
	const t = useTranslations();
	const caseStudyTeaserData = getFragmentData(
		CaseStudyListElementFragment,
		caseStudyTeaserSlots,
	);

	const {
		cardsContainerRef,
		cardListRef,
		cardRefs,
		canScrollPrev,
		canScrollNext,
		handleScrollPrev,
		handleScrollNext,
	} = useTeaserCards();

	if (!caseStudyTeaserData.length) return null;

	return (
		<BaseSection
			paddingTop={Spacing.xxLarge}
			paddingBottom={Spacing.xxLarge}
			className="bg-bluish"
		>
			<ContentContainerTw>
				<div className="flex flex-col gap-10 md:gap-20">
					<IntroBlock
						headingAddOn={t("caseStudies")}
						heading={t("readAlso")}
						className="text-white"
					/>

					<div className="flex flex-col gap-5">
						<ScrollAreaPrimitive.Root className="w-full overflow-hidden">
							<ScrollAreaPrimitive.Viewport
								ref={cardsContainerRef}
								className="h-full w-full rounded-lg"
							>
								<div
									ref={cardListRef}
									className="flex w-full flex-row gap-4"
								>
									{caseStudyTeaserData.map(
										(caseStudy, index) => {
											const imagesData =
												caseStudy.images.map((d) =>
													readCmsImageData(d),
												);
											const labels = stringListToArray(
												caseStudy.tags,
											).map((tag) => ({
												name: tag,
												isActive: false,
											}));
											const action = caseStudy.detailPage
												? {
														label:
															caseStudy.detailPageLabel ??
															t("viewProject"),
														url: caseStudy
															.detailPage
															.routeName,
													}
												: undefined;
											return (
												<div
													key={caseStudy.id}
													className={cn("w-full", {
														"min-w-[388px]":
															caseStudyTeaserData.length >
															1,
													})}
													ref={(el) => {
														cardRefs.current[
															index
														] = el;
													}}
												>
													<Card
														name={caseStudy.name}
														headline={
															caseStudy.headline
														}
														labels={labels}
														description={
															caseStudy.description ??
															undefined
														}
														action={action}
														websiteUrl={
															caseStudy.websiteUrl ??
															undefined
														}
														imagesData={imagesData}
													/>
												</div>
											);
										},
									)}
								</div>
							</ScrollAreaPrimitive.Viewport>
							<ScrollAreaPrimitive.Scrollbar orientation="horizontal">
								<ScrollAreaPrimitive.Thumb />
							</ScrollAreaPrimitive.Scrollbar>
						</ScrollAreaPrimitive.Root>

						{(canScrollPrev || canScrollNext) &&
						caseStudyTeaserData.length > 1 ? (
							<div className="flex items-center justify-end gap-2">
								<div className="flex items-center gap-1 rounded-full bg-white p-1 shadow-card">
									<Button
										variant="iconOnly"
										aria-label={t("prevSlide")}
										disabled={!canScrollPrev}
										onClick={handleScrollPrev}
									>
										<NavLeftIcon />
									</Button>
									<Button
										variant="iconOnly"
										aria-label={t("nextSlide")}
										disabled={!canScrollNext}
										onClick={handleScrollNext}
									>
										<NavRightIcon />
									</Button>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</ContentContainerTw>
		</BaseSection>
	);
};

export default CaseStudyTeaser;
